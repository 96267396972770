import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { IconPremium } from 'components/Icon';
import { smallTextStyles } from 'typography';
import { PremiumBadgeVariants } from './PremiumBadge.constant';

const IconStyled = styled(IconPremium)`
    display: inline-flex;
    vertical-align: middle;

    ${({ theme }) => `
      height: ${theme.spacing[1.5]};
      margin-right: ${theme.spacing[1]};
      width: ${theme.spacing[1.5]};
    `};
`;

const PremiumBadgeStyled = styled.div`
    font-size: 10px;
    line-height: 2.4;
    display: flex;
    align-items: center;

    ${({ theme }) => `
        background-color: ${theme.palette.primaryDarkest};
        border-radius: ${theme.borderRadius.small};
        color: ${theme.palette.white};
        padding: 0 ${theme.spacing[1]};


    `};

    ${({ theme, variant }) =>
        variant === PremiumBadgeVariants.LARGE_FONT &&
        css`
            // 720
            @media screen and (min-width: ${theme.screenSize.mediumTablet}px) {
                ${smallTextStyles};
            }
        `};
`;

const PremiumBadge = ({ textString = 'components_badges_premium', className, variant }) => {
    const { t } = useTranslation();

    return (
        <PremiumBadgeStyled className={className} variant={variant}>
            <IconStyled palette="yellow" />
            {t(textString)}
        </PremiumBadgeStyled>
    );
};

PremiumBadge.propTypes = {
    /**
     * a string that can be matched in our translation.json file
     */
    textString: PropTypes.string.isRequired,

    /**
     * className passed down by styled components
     */
    className: PropTypes.string,

    /**
     * Optional variant to determine font-size
     */
    variant: PropTypes.string,
};

PremiumBadge.defaultProps = {
    variant: PremiumBadgeVariants.SMALL_FONT,
};

PremiumBadge.Variant = PremiumBadgeVariants;

export default PremiumBadge;
